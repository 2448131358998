// dateUtils

export const formatUtcToLocal = (utcTimestamp, format) => {
    if (!utcTimestamp) return '';
  
    // Convert UTC timestamp to local Date object
    const localDate = new Date(utcTimestamp);
  
    
    const year = localDate.getFullYear();
    const month = localDate.getMonth(); 
    const day = localDate.getDate().toString().padStart(2, '0');
    const hours24 = localDate.getHours();
    const minutes = localDate.getMinutes().toString().padStart(2, '0');
    const seconds = localDate.getSeconds().toString().padStart(2, '0');
  
    // Prepare month and period (AM/PM) representations
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthFullName = monthNames[month];
    const monthShortName = monthShortNames[month];
  
    // 12-hour format calculation
    const hours12 = (hours24 % 12 || 12).toString().padStart(2, '0');
   
  
    // Replace format tokens with actual date components
    const formattedDate = format
      .replace('MMMM', monthFullName) // Full month name
      .replace('MMM', monthShortName) // Short month name
      .replace('MM', (month + 1).toString().padStart(2, '0')) // Month number with leading zero
      .replace('yyyy', year) // Full year
      .replace('yy', year.toString().slice(-2)) // Last two digits of the year
      .replace('dd', day) // Day with leading zero
      .replace('hh', hours12) // Hours in 12-hour format with leading zero
      .replace('HH', hours24.toString().padStart(2, '0')) // Hours in 24-hour format with leading zero
      .replace('mm', minutes) // Minutes with leading zero
      .replace('ss', seconds) // Seconds with leading zero
     
  
    return formattedDate;
  };
  